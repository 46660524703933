html,
body {
  font-family: "Gotham A", "Gotham B", sans-serif !important;
  min-height: 100%;
  min-width: 100%;
}

body {
  background:
    url(../static/img/taustakuva4.jpg)
    center/cover no-repeat !important;
  text-align: center;
  padding: 1rem 0;

  @media screen and (min-width: 440px) {
    padding: 2rem 0;
  }

  &.pim {
    background-image: url(../static/img/taustakuva4.jpg) !important;
  }
}

a {
  color: black !important;
}

.outest-container {
  display: table;
  min-height: calc(100vh - 4rem);
  width: 100%;
}

.outer-container {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.container {
  background-color: #fff;
  padding: 2rem;
  max-width: 300px;
  display: inline-block;
  margin: 0 1rem;
}

.container h1 {
  text-align: left;
}

#logo {
  background: url(../static/img/logo.png) left/contain no-repeat;
  color: transparent !important;
  width: 70%;
  height: 50px;
  display: flex;
  text-align: right;
  vertical-align: middle;
}

#logo-raiski {
  display: none !important;
  height: 80px;
  width: calc(30% - 5px);
  background: url(../static/img/raiski.jpg?c=system_150) right/contain no-repeat;
  color: transparent !important;
  vertical-align: middle;
}

h1 {
  font-size: 2rem;
  font-weight: bold;
  margin: 1em 0;
  line-height: 1;
}

h2 {
  font-size: 1.25rem;
  margin: 1em 0;
}

.field {
  width: 100% !important;
}

p.stage-error,
label.stage-error {
  color: $red !important;
}

form {
  width: 100% !important;
  max-width: 350px;

  input[type="text"]:not(#recaptcha_response_field),
  input[type="password"],
  input[type="submit"],
  textarea,
  select {
    font-size: 150%;
    height: 38px;
    border: 1px solid #335a70;
    outline: none;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin: 0.25rem 0 !important;
    width: calc(100% - 1rem) !important;
    border-radius: 0 !important;
    border-color: black !important;
    background-color: #f1f1f1 !important;

    &.stage-error {
      border-color: $red !important;
    }
  }

  textarea {
    height: 10em;
    padding: 0.5rem;
  }

  select {
    width: 100% !important;
  }

  &#auth-form input,
  input#email,
  input.password {
    &[type="text"],
    &[type="password"] {
      width: calc(100% - 36px) !important;
      padding: 0 0 0 34px;
    }

    &#email {
      background:
        #e9ebec
        url(https://secure.login.crasman.fi/img/login-username-icon-24x24.png)
        no-repeat 0.4em 0.5em;
    }

    &.password {
      background:
        #e9ebec
        url(https://secure.login.crasman.fi/img/login-password-icon-24x24.png)
        no-repeat 0.4em 0.5em;
    }
  }

  input[type="submit"] {
    cursor: pointer;
    height: 40px;
    font-weight: bold;
    color: #fff;
    text-shadow: 0 0.1em 0.1em #172f40;
    border: 1px solid #172f40;
    width: 100% !important;
    background: #b1b3b2 !important;
  }

  input[type="submit"]:hover {
    background: #b9b9b9 !important;
  }

  .field label {
    color: black;
    text-shadow: none;
    text-align: left;
    font-weight: normal;
    margin-bottom: -5px;
    margin-top: 5px;

    &.stage-required::after {
      content: " *";
      color: red;
    }

    &[for="verification"] {
      margin-bottom: 0;
    }
  }

  #recaptcha_area,
  #recaptcha_table {
    max-width: 300px;
  }
}

.links > a:not(:last-child) {
  border-right: 1px solid black;
  padding-right: 0.5em;
}

#product-selector {
  margin: 0 !important;
}

.authmethods {
  display: none !important;
}
